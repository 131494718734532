<template>
	<div class="text-center">
		<template v-if="title == 'OverSOS Jobs launch'">
			<v-dialog :value="active" persistent width="400" @input="close()">
				<v-card v-if="step == 1" transition="scroll-x-transition" rounded="xl">
					<v-img class="ma-auto" contain height="160" :src="require(`@/assets/images/popup/launch/startup-launch.png`)" />
					<v-card-title class="text-h5">
						<span class="mx-auto">OverSOS Jobs</span>
					</v-card-title>
					<v-card-subtitle class="text-subtitle-2 text-center pt-1">
						{{ $t('popup.jobsLaunch.firstSubtitle') }}
					</v-card-subtitle>

					<v-card-text>
						<v-list class="py-0" two-line color="transparent">
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-rocket-launch </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.jobsLaunch.firstLaunchTitle') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.jobsLaunch.firstLaunchText') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-divider inset />

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-chevron-triple-up </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.jobsLaunch.firstAimTitle') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.jobsLaunch.firstAimtext') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>

					<i18n path="popup.illustratedBy" tag="p" class="text-caption mx-3 my-0 py-0 text--disabled">
						<template #author>
							<a href="https://iconscout.com/illustrations/startup-launch" target="_blank" class="text-decoration-none"> Iconscout </a>
						</template>
					</i18n>

					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" text rounded @click="nextStep()">
							{{ $t('search.next') }}
						</v-btn>
					</v-card-actions>
				</v-card>

				<v-card v-if="step == 2" transition="scroll-x-transition" rounded="xl">
					<v-img class="ma-auto" contain height="180" :src="require(`@/assets/images/popup/launch/online-man-no-bg2.png`)" />
					<v-card-title class="text-h5">
						<span class="mx-auto">OverSOS Jobs</span>
					</v-card-title>
					<v-card-subtitle class="text-subtitle-2 text-center pt-1">
						{{ $t('popup.jobsLaunch.secondSubtitle') }}
					</v-card-subtitle>

					<v-card-text>
						<v-list class="py-0" two-line color="transparent">
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-briefcase </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.jobsLaunch.secondFindTitle') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.jobsLaunch.secondFindText') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-divider inset />

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-cloud-check </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title style="white-space: normal"> 24 / 7 / 365 </v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.jobsLaunch.secondOnlineText') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>

					<v-card-actions>
						<v-btn color="primary" rounded text @click="previousStep()">
							{{ $t('search.back') }}
						</v-btn>
						<v-spacer />
						<v-btn color="primary" rounded text @click="nextStep()">
							{{ $t('search.next') }}
						</v-btn>
					</v-card-actions>
				</v-card>

				<v-card v-if="step == 3" transition="scroll-x-transition" rounded="xl">
					<v-img class="ma-auto" contain height="180" :src="require(`@/assets/images/popup/launch/girl.png`)" />
					<v-card-title class="text-h5">
						<span class="mx-auto">OverSOS Jobs</span>
					</v-card-title>
					<v-card-subtitle class="text-subtitle-2 text-center pt-1">
						{{ $t('popup.jobsLaunch.thirdSubtitle') }}
					</v-card-subtitle>

					<v-card-text>
						<v-list class="py-0" two-line color="transparent">
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-sitemap </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.jobsLaunch.thirdStartTitle') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.jobsLaunch.thirdStartText') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-divider inset />

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-wan </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.jobsLaunch.thirdRedirectTitle') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.jobsLaunch.thirdRedirectText') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>

					<v-card-actions>
						<v-btn color="primary" text rounded @click="previousStep()">
							{{ $t('search.back') }}
						</v-btn>
						<v-spacer />
						<v-btn color="secondary" text rounded small @click="doNotShowAgain()">
							{{ $t('search.doNowShowAgain') }}
						</v-btn>
						<v-btn color="primary" text rounded @click="close()">
							{{ $t('search.close') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
		<template v-else-if="title == 'Join our Talent Pool'">
			<v-dialog :value="active" persistent width="400" @input="close()">
				<v-card rounded="xl">
					<v-img class="ma-auto" contain height="140" :src="require(`@/assets/images/popup/launch/startup-launch.png`)" />
					<v-card-title class="text-h5">
						<span class="mx-auto">
							{{ $t('popup.talentPool.firstTitle') }}
						</span>
					</v-card-title>
					<v-card-subtitle class="text-subtitle-2 text-center pt-1 pb-1">
						{{ $t('popup.talentPool.firstSubtitle') }}
					</v-card-subtitle>

					<v-card-text class="mt-0">
						<v-list class="py-0" two-line color="transparent">
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-magnify </v-icon>
								</v-list-item-icon>

								<v-list-item-content class="py-2">
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.talentPool.first1Title') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.talentPool.first1Text') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-rocket-launch </v-icon>
								</v-list-item-icon>

								<v-list-item-content class="py-2">
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.talentPool.first2Title') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.talentPool.first2text') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-clock-outline </v-icon>
								</v-list-item-icon>

								<v-list-item-content class="py-2">
									<v-list-item-title style="white-space: normal">
										{{ $t('popup.talentPool.first3Title') }}
									</v-list-item-title>
									<v-list-item-subtitle style="white-space: normal">
										{{ $t('popup.talentPool.first3text') }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>

					<v-card-actions class="pt-0">
						<v-btn color="primary" text small rounded @click="close()">
							{{ $t('search.close') }}
						</v-btn>
						<v-btn color="secondary" text rounded x-small @click="doNotShowAgain()">
							{{ $t('search.doNowShowAgain') }}
						</v-btn>
						<v-spacer />
						<v-btn color="primary" rounded @click="callChangeTalentPool()">
							{{ $t('popup.talentPool.join') }}
						</v-btn>
					</v-card-actions>

					<i18n path="popup.illustratedBy" tag="p" class="text-caption mx-3 my-0 py-0 text--disabled">
						<template #author>
							<a href="https://iconscout.com/illustrations/startup-launch" target="_blank" class="text-decoration-none"> Iconscout </a>
						</template>
					</i18n>
				</v-card>
			</v-dialog>
		</template>
		<template v-else>
			<v-dialog :value="active" persistent width="400" @input="close()">
				<v-card rounded="xl">
					<v-card-title class="headline">
						{{ title }}
					</v-card-title>
					<v-card-text>
						{{ message }}
					</v-card-text>

					<v-card-actions>
						<v-spacer />
						<v-btn color="secondary" text small @click="doNotShowAgain()">
							{{ $t('search.doNowShowAgain') }}
						</v-btn>
						<v-btn color="primary" text @click="close()">
							{{ $t('search.close') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'PopUp',
	data() {
		return {
			step: 1,
		}
	},
	computed: {
		...mapGetters({
			active: 'popup/active',
			title: 'popup/title',
			message: 'popup/message',
		}),
	},
	methods: {
		nextStep() {
			this.step++
		},
		previousStep() {
			this.step--
			if (this.step < 1) this.step = 1
		},
		callChangeTalentPool() {
			this.changeTalentPool({
				talentPool: true,
			})
				.then(() => {
					this.doNotShowAgain()
				})
				.then(() => {
					this.$router.push({ name: 'User' })
				})
		},
		...mapActions('settings', ['changeTalentPool']),
		...mapActions('popup', ['close', 'doNotShowAgain']),
	},
}
</script>
